import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { RouterDefinition, RouteMenuLinks } from './routes';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import { AuthProvider, AuthContext } from './shared/useAuth';
import { SnackbarProvider } from 'notistack';
import { SnackbarRef, SnackbarUtilsConfigurator } from './shared/globalNotification';
import Box from '@mui/material/Box';
import { PaletteMode } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { QueryClient, QueryClientProvider } from 'react-query'
import { getErrorMessage } from './shared/utils';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Brightness4Icon, Brightness7Icon, MenuIcon } from './shared/muiIcons';

import './App.scss';
import SignIn from './pages/signin';

// Data-grid-pro
LicenseInfo.setLicenseKey(
	'9ee6e40584021a0d71823ba727fe80c1Tz00NjAyNSxFPTE2ODczODAxNzI0NzMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

const getThemeOptions = (mode: PaletteMode): any => (
	mode === 'light' ? {
		palette: {
			mode: 'light'
		}
	} : {
		palette: {
			mode: 'dark'
		}
	}
);

const queryClient = new QueryClient({
	defaultOptions: {
		mutations: {
			retry: 0,
			onError: (error) => {
				console.error(error);
				SnackbarRef.enqueueSnackbar('Request failed: ' + getErrorMessage(error), { variant: 'error' })
			},
			onSuccess: (a, b, c) => {
				SnackbarRef.enqueueSnackbar('Updated', { variant: 'success' })
			},
		},
		queries: {
			retry: 1,
			retryDelay: 1000,
			refetchOnWindowFocus: false,
			onError: (error) => {
				console.log("QC error", error);
				SnackbarRef.enqueueSnackbar('Request failed: ' + getErrorMessage(error), { variant: 'error' })
			}
		}
	}
});

function App() {
	const [navOpen, setNavOpen] = useState(window.localStorage.getItem('navOpen') === 'true');
	const [mode, setMode] = React.useState<'light' | 'dark'>(window.localStorage.getItem('theme') === 'light' ? 'light' : 'dark');
	const theme = React.useMemo(() => createTheme(getThemeOptions(mode)), [mode]);
	const isSmallScreen = window.innerWidth <= 769;

	useEffect(() => {
		window.localStorage.setItem('theme', mode);
	}, [mode])

	useEffect(() => {
		window.localStorage.setItem('navOpen', navOpen + '');
	}, [navOpen])

	function toggleColorMode() {
		setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
	}

	const signInLanding = () => {
		return <div className={'main-container-wrapper ' + mode}>
			<Container maxWidth={false} sx={{ overflowX: 'hidden' }}>
				<div className='header-v-padding'></div>
				<main>
					<title className='app-title'>
						<h1>Tanker Monitor</h1>
						<h3>Fonterra tanker monitoring</h3>
					</title>
					<SignIn />
				</main>
			</Container>
		</div>
	}

	const mainApp = (userName: string | undefined) => {
		return (<>
			<AppBar position="fixed" color="default" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar>
					<IconButton aria-label="open menu" onClick={() => setNavOpen(!navOpen)}>
						<MenuIcon />
					</IconButton>
					<img src={theme.palette.mode === 'dark' ? '/logo_white.png' : '/logo.png'} alt='' style={{maxHeight: '35px', marginLeft: '10px'}}/>
					<div style={{ flex: 1 }}></div>
					<Box display={{ xs: 'none', sm: 'block' }}>
						{userName}
					</Box>
					<IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
						{theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
					</IconButton>
				</Toolbar>
			</AppBar>

			<div className={'main-container-wrapper ' + mode}>
				<div className={navOpen ? 'main-container-draw-spacer open' : 'main-container-draw-spacer'}></div>
				<Container maxWidth={false} sx={{ overflowX: 'hidden'}} style={{padding:'0px'}}>
					<div className='header-v-padding'></div>
					<Drawer
						anchor={'left'}
						variant={isSmallScreen ? "temporary" : "persistent"}
						open={navOpen}
						onClose={() => setNavOpen(!navOpen)}
						ModalProps={{
							keepMounted: true,
						}}
						PaperProps={{
							sx: { backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900] }
						}}
					>
						<div className='header-v-padding' style={{ minWidth: '230px' }}></div>
						<Box mt={2} ml={2} display={{ xs: 'block', sm: 'none' }}>
							{userName}
						</Box>
						<RouteMenuLinks onRouteClick={() => {
							if (isSmallScreen) {
								setNavOpen(false);
							}
						}} />
					</Drawer>
					<main>
						<RouterDefinition />
					</main>
				</Container>
			</div>
		</>);
	}

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<SnackbarUtilsConfigurator />
				<BrowserRouter>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<QueryClientProvider client={queryClient}>
							<AuthProvider>
								<AuthContext.Consumer>
									{authContext => {
										return (<>
											<CssBaseline />
											{authContext.userAuthed && mainApp(authContext.user?.userName)}
											{!authContext.userAuthed && signInLanding()}
											</>)
									}}
								</AuthContext.Consumer>
							</AuthProvider>
						</QueryClientProvider>
					</LocalizationProvider>
				</BrowserRouter>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;