import { FillLayer, LineLayer, CircleLayer, SymbolLayer } from "mapbox-gl";

// Filters https://docs.mapbox.com/mapbox-gl-js/style-spec/other/#other-filter
export const polygonLayer: FillLayer = {
	id: 'polygonLayer',
	type: 'fill',
	paint: {
		'fill-color': 'red',
		'fill-opacity': 0.8,
	},
};

export const regionPolyLayer: FillLayer = {
	id: 'region-poly-layer',
	type: 'fill',
	paint: {
		'fill-color': ['get', 'colour'],
		'fill-opacity': 0.2,
	},
};

export const aucklandRouteLayer: LineLayer = {
	id: 'auckland-route-layer',
	type: 'line',
	paint: {
		'line-color': '#f993a5', // pink
		'line-width': 4,
		'line-opacity': 0.6
	},
};

export const regionLineLayer: LineLayer = {
	id: 'region-line-layer',
	type: 'line',
	paint: {
		'line-color': 'black',
		'line-width': 1,
		'line-opacity': 0.4
	},
};

export const polygonOutlineLayer: LineLayer = {
	id: 'polygonOutlineLayer',
	type: 'line',
	paint: {
		'line-color': 'black',
		'line-width': 2,
		'line-opacity': 1
	},
};

export const lineLayer: LineLayer = {
	id: 'route-line-layer',
	type: 'line',
	paint: {
		'line-color': '#0C9BFF',
		'line-width': 6,
		'line-opacity': 0.8
	},
};

export const levelCrossingsLight: SymbolLayer = {
	id: 'train-level-crossings',
	type: "symbol",
	minzoom: 10,
	layout: {
		'symbol-placement': 'point',
		'text-field': ["step", ["zoom"], "", 13, ["get", "alcamID"]],
		'text-anchor': 'top',
		'icon-allow-overlap': true,
		'icon-size': [
			"step", ["zoom"],
			// zoom is 5 far (or less) -> circle radius will be 1px
			0.2,
			// zoom is 10 close (or greater) -> circle radius will be 5px
			10, 0.3
		],
		'icon-image': 'crossing-black',
		'text-offset': [0, 0.5],
	},
	paint: {
		'text-color': 'black',
	}
}

export const levelCrossingsDark: SymbolLayer = {
	id: 'train-level-crossings',
	type: "symbol",
	minzoom: 10,
	layout: {
		'symbol-placement': 'point',
		'text-field': ["step", ["zoom"], "", 13, ["get", "alcamID"]],
		'text-anchor': 'top',
		'icon-allow-overlap': true,
		'icon-size': [
			"step", ["zoom"],
			0.2,
			10, 0.3
		],
		'icon-image': 'crossing-white',
		'text-offset': [0, 0.5],
	},
	paint: {
		'text-color': 'white'
	}
}

export const pointLayer: CircleLayer = {
	id: 'point',
	type: 'circle',
	paint: {
		'circle-radius': 8,
		'circle-color': 'green',
		'circle-opacity': 0.5,
		'circle-stroke-width': 1,
		'circle-stroke-color': 'black',
		'circle-stroke-opacity': 1
	},
};

export const geolocateStyle = {
	top: 0,
	left: 0,
	margin: 10
};

// 'EventType'
//  Road Hazard
//  Road Work
//  Area Warning
//  Compliment
//  Complaint
//  Damage Report 

// 'Impact'
//  Delays
//  Caution
//  Road Closed
//  Vehicle Restrictions etc. 

export const hwLineLayer: LineLayer = {
	id: 'hw-line-layer',
	type: 'line',
	paint: {
		// Road Closed / Caution / Vehicle Restrictions / Delays
		'line-color': ['match', ['get', 'Impact'],
			'Road Closed', 'black',
			'#E75829'
		],
		'line-width': 4,
		'line-opacity': 0.8
	},
};

export const hwSymbolLayer: SymbolLayer = {
	id: 'hw-symbol-layer',
	type: "symbol",
	layout: {
		'symbol-placement': 'point',
		'icon-anchor': 'bottom',
		'icon-allow-overlap': true,
		// Road Hazard, Road Work, Area Warning, Compliment, Complaint, Damage Report
		// 'icon-image': ['match', ['get', 'EventType'],
		// 	'Road Hazard', 'error',
		// 	'Area Warning', 'warning',
		// 	'Road Work', 'roadworks',
		// 	'info'
		// ],

		'icon-image': ['match', ['get', 'Impact'],
			'Road Closed', 'closed',
			'Delays', 'warning',
			'Caution', ['match', ['get', 'EventType'],
				'Road Hazard', 'error',
				'Area Warning', 'warning',
				'Road Work', 'roadworks',
				'error'
			],
			'Vehicle Restrictions', 'warning',
			'info'
		],

		// 'icon-image': ['match', ['get', 'EventType'],
		// 	'Road Hazard', 'error',
		// 	'Area Warning', 'warning',
		// 	'Road Work', 'roadworks',
		// 	'error'
		// ],

		'icon-size': [
			"interpolate", ["linear"], ["zoom"],
			5, 0.4,
			10, 1
		],



	},
}