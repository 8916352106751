import { Box } from "@mui/material";
import Container from "@mui/system/Container";
import { GridActionsCellItem, GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import DataGridCustomToolbar from "../components/dataGridCustomToolbar";
import { DialogHelper } from "../components/dialogHelper";
import { AddEditTankerGroupForm } from "../forms/addEditTankerGroupForm";
import { ApiClient, CI } from "../services";
import { DeleteIcon, EditIcon } from "../shared/muiIcons";
import { field } from "../shared/utils";

export function TankerGroupList() {
	const [editGroupId, setEditGroupId] = useState<string | null>();
	const { data: groups, isLoading, refetch: reloadGroups } = useQuery(['TankerGroupsList'], async () => ApiClient.tankerGroupV1List());
	const addGroupMutation = useMutation((newGroup: CI.TankerGroupAddDto) => { return ApiClient.tankerGroupV1Add(newGroup); });
	const editGroupMutation = useMutation((editGroup: CI.TankerGroupDto) => { return ApiClient.tankerGroupV1Edit(editGroup); });
	const deleteGroupMutation = useMutation((groupId: string) => { return ApiClient.tankerGroupV1Delete(groupId); });

	const deleteGroup = useCallback(async (id: string) => {
		await deleteGroupMutation.mutateAsync(id);
		reloadGroups();
	}, [deleteGroupMutation, reloadGroups]);

	const columns = useMemo<GridColumns<CI.TankerGroupDto>>(() => [
		{
			field: field<CI.TankerGroupDto>().name,
			headerName: 'Code',
			flex: 2,
			minWidth: 100,
		},
		{
			field: field<CI.TankerGroupDto>().tankers,
			renderCell: (params: GridRenderCellParams<CI.TankerGroupDto>) => <div>
				{params.row.tankers.map((x: CI.TankerListItemDto) => <div key={x.id+params.row.id}>{x.code}</div>)}
			</div>,
			headerName: 'Tankers',
			flex: 2,
			minWidth: 100,
		},
		{
			field: 'actions',
			type: 'actions',
			width: 120,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<EditIcon />}
					label="Edit"
					onClick={() => setEditGroupId(params.row.id)}
				/>,
				<GridActionsCellItem
					icon={<DeleteIcon />}
					label="Delete"
					onClick={() => deleteGroup(params.row.id)}
					showInMenu
				/>,
			],
		},
	], [deleteGroup]);

	return (
		<Container disableGutters>
			<Box justifyItems={'center'} display="flex" alignItems="center" flexDirection='column' height={'100%'}>
				<h1>Groups</h1>

				<div style={{ height: 400, width: '100%' }}>
					<DataGridPro
						rows={groups ?? []}
						columns={columns}
						disableSelectionOnClick
						loading={isLoading || deleteGroupMutation.isLoading || addGroupMutation.isLoading}
						disableMultipleColumnsFiltering
						disableMultipleSelection
						disableColumnSelector
						disableColumnPinning
						disableColumnResize
						components={{
							Toolbar: () => <DataGridCustomToolbar onAdd={() => setEditGroupId(null)} />,
						}}
					/>
				</div>

				<DialogHelper
					title={editGroupId === null ? 'Add Group' : 'Edit Group'}
					open={editGroupId !== undefined}
				>
					<AddEditTankerGroupForm
						groupId={editGroupId ?? null}
						autoFocus={true}
						hideOptional={true}
						onCancel={() => setEditGroupId(undefined)}
						onAdd={async (data: CI.TankerGroupAddDto) => {
							await addGroupMutation.mutateAsync(data);
							setEditGroupId(undefined);
							await reloadGroups();
							
						}} 
						onEdit={async (data: CI.TankerGroupDto) => {
							await editGroupMutation.mutateAsync(data);
							setEditGroupId(undefined);
							await reloadGroups();	
						}}
						/>
				</DialogHelper>
			</Box>
		</Container>
	);
}
