import { ChangeEvent, useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useQuery } from "react-query";
import { ApiClient, CI } from "../services";
import { GroupWorkIcon } from "../shared/muiIcons";

import './tankerGroupControl.scss';
import { Divider, Slider } from "@mui/material";

type LayerControlProps = {
	onGroupsSelected: (tankerIds: string[]) => void;
	onSignalStrengthChosen: (sigRange: number[]) => void;	
	onLatencyChosen: (sigRange: number[]) => void;
	onFilterByDataChosen: (checked: boolean) => void;
};

export function TankerGroupControl(props: LayerControlProps) {
	const { onGroupsSelected, onSignalStrengthChosen, onLatencyChosen, onFilterByDataChosen } = props;
	const [open, setOpen] = useState(false);
	const { data: tankerGroups, isLoading: tankerGroupsLoading } = useQuery(['tanker-groups'], async () => ApiClient.tankerGroupV1List());

	const [selectedGroups, setSelectedGroups] = useState<CI.TankerGroupDto[]>([])


	const onClickGroup = useCallback((group: CI.TankerGroupDto, checked: boolean) => {
		setSelectedGroups(oldGroups => {
			if (checked) {
				return [group, ...oldGroups];
			} else {
				return [...oldGroups.filter(x => x.id !== group.id)];
			}
		});
	}, []);

	const [latency, setLatency] = useState<number[]>([0,1000]);
	const [sigValue, setSigValue] = useState<number[]>([0, 4]);
	const [onlyShowNoData, setOnlyShowNoData] = useState(false);

	const handleSigChange = useCallback((event: Event, newValue: number | number[]) => {
		setSigValue(newValue as number[]);
		onSignalStrengthChosen(newValue as number[]);
	},[onSignalStrengthChosen]);

	const handleLatencyChange = useCallback((event: Event, newValue: number | number[]) => {
		setLatency(newValue as number[]);
		onLatencyChosen(newValue as number[]);
	},[onLatencyChosen]);

	
	const handleDataChange = useCallback((event: ChangeEvent, checked: boolean) => {
		setOnlyShowNoData(!onlyShowNoData)
		onFilterByDataChosen(checked);
	},[onFilterByDataChosen, onlyShowNoData]);



	useEffect(() => {
		let tankerList = selectedGroups.flatMap(x => x.tankers).filter(x => !!x).map(x => x!.code);
		onGroupsSelected(tankerList);
	}, [selectedGroups, onGroupsSelected])

	
	return <div className="tanker-group-control-container">
		<div className="button-container">
			{tankerGroupsLoading && <CircularProgress size={25} />}
			{!tankerGroupsLoading && <div className="button" onClick={() => setOpen(!open)}>
				<GroupWorkIcon />
			</div>}
		</div>
		{open && <div className="popup">
			<FormControlLabel control={<Switch checked={onlyShowNoData} onChange={handleDataChange}/>} label="No data only" />
			<Divider></Divider>
			<div className="slider-container">
				<p>Latency</p>
				<Slider
					size="small"
					value={latency}
					min={0}
					max={1000}
					disableSwap={true}
					onChange={handleLatencyChange}
					valueLabelDisplay="auto"
				/>
			</div>
			<Divider></Divider>
			<div className="slider-container">
				<p>Signal strength</p>
				<Slider
					size="small"
					value={sigValue}
					min={0}
					max={4}
					disableSwap={true}
					onChange={handleSigChange}
					valueLabelDisplay="auto"
				/>
			</div>
			<Divider></Divider>
			{(tankerGroups ?? []).map(l => {
				return (<div key={l.name}>
					<FormControlLabel control={<Switch checked={selectedGroups.includes(l)} onChange={(_, checked: boolean) => onClickGroup(l, checked)} />} label={l.name} />
				</div>);
			})}
		</div>}
	</div>
}
