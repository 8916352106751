
import * as React from 'react';
import { Container, CssBaseline, Box, Typography, TextField, FormControlLabel, Checkbox, useTheme, ThemeProvider, Alert } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useParams } from "react-router-dom";
import { CI } from "../services";

import useAuth from '../shared/useAuth';
import { useState } from 'react';

const messages: Record<string, any> = {};
messages['401'] = 'Unauthorized, please sign in';

// Example SSO error http://localhost:3002/sign-in/External%20login%20failed,%20no%20username%20associated%20with%20identity

function SignIn() {
	const { loading, signIn } = useAuth();
	const { message } = useParams();
	const theme = useTheme();

	const [userName, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [rememberMe, setRememberMe] = useState(true);

	async function onSignIn(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		const request = new CI.SignInDto({
			userName: userName,
			password: password,
			rememberMe: data.get('remember') === 'true',
		});

		signIn(request, (appState: CI.AppStateDto) => {
			return '/app/home';
		});
	}

	function onTorutekSignin() {
		if (process.env.REACT_APP_API_URL) {
			window.location.href = process.env.REACT_APP_API_URL + "/api/TorutekSingleSignOn/v1/sso-sign-in?userName=" + userName;
		} else {
			window.location.href = "/api/TorutekSingleSignOn/v1/sso-sign-in?userName=" + userName;
		}
	}

	return (
		<ThemeProvider theme={theme}>
			<Container disableGutters maxWidth="xs">
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					{message && <Alert severity="error">
						{messages[message] ?? message}
					</Alert>}
					<Box component="form" onSubmit={onSignIn} noValidate sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							required
							fullWidth
							id="username"
							label="Username"
							name="username"
							autoComplete="username"
							autoFocus
							value={userName}
							onChange={e => setUsername(e.target.value)}
						/>

						<TextField
							margin="normal"
							required
							fullWidth
							id="password"
							label="Password"
							name="password"
							type="password"
							autoComplete="password"
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
						<FormControlLabel
							control={<Checkbox checked={rememberMe} onChange={() => setRememberMe(!rememberMe) } name="remember" value="true" color="primary" />}
							label="Remember me"
						/>

						<LoadingButton
							loading={loading}
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Sign In
						</LoadingButton>
						{userName.indexOf('torutek') > -1 && <LoadingButton
							onClick={() => onTorutekSignin()}
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Torutek SSO
						</LoadingButton>}
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
}

export default SignIn;