import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React, { FC, useEffect, useState } from 'react';

interface ICentredLoadingProps {
	description?: string;
	// Set to true if you want a reload button to appear after 10 seconds
	showReload?: boolean;
}

const CentredLoading: FC<ICentredLoadingProps> = (props: ICentredLoadingProps) => {
	const [showReload, setShowReload] = useState(false);

	useEffect(() => {
		setShowReload(false);
	}, [])

	useEffect(() => {
		if (props.showReload) {
			let timeOut = setTimeout(() => {
				setShowReload(true);
			}, 10000);
			return () => {
				clearTimeout(timeOut);
			}
		}
	}, [props.showReload]);

	return (
		<Box sx={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<CircularProgress size={40} />
			{props.description && <div style={{ marginTop: '10px' }}>{props.description}</div>}
			{showReload && <Button
				style={{ marginTop: '10px' }}
				variant='outlined'
				onClick={() => window.location.reload()}
			>Reload</Button>}
		</Box>
	);
};

export default CentredLoading;