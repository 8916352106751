import { Radio, RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { LayersIcon } from "../shared/muiIcons"

import './layerControl.scss';

export type MapLayer = "Obstacles" | "Regions" | "Auckland routes" | "Level crossings" | "Highway info" | "Topography (Zoomed in)" | "Time slider" | "Farm vats (Standard, Zoomed in)";
export type MapStyleChoice = "Satellite" | "Street" | "Standard" | "Satellite Streets";

export type LayerOption = {
	name: MapLayer;
	checked: boolean;
}

export type StyleOption = {
	name: MapStyleChoice;
	checked: boolean;
}

type LayerControlProps = {
	layerOptions: LayerOption[];
	styleOptions: StyleOption[];
	chosenStyle: MapStyleChoice;
	onClickLayer: (name: MapLayer) => void;
	onClickStyle: ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void) | undefined
};

export function LayerControl(props: LayerControlProps) {
	const [open, setOpen] = useState(false);

	return <div className="layer-control-container">
		{open && <div className="layer-control-popup">
			<RadioGroup
				name="radio-buttons-group"
				row={true}
				value={props.chosenStyle}
				onChange={props.onClickStyle}
			>
				{props.styleOptions.map(s => {
					return (<div key={s.name}>
						<FormControlLabel value={s.name} control={<Radio/>} label={s.name} />
					</div>)
				})}
			</RadioGroup>
			
			{props.layerOptions.map(l => {
				return (<div key={l.name}>
					<FormControlLabel control={<Switch checked={l.checked} onChange={() => props.onClickLayer(l.name)} />} label={l.name} />
				</div>);
			})}
		</div>}
		<div className="layer-control-button" onClick={() => setOpen(!open)}>
			<LayersIcon />
		</div>
	</div>
}
