import { useSnackbar } from "notistack";
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiClient, CI } from "../services";

export class Roles {
	public static GlobalAdmin = 'GlobalAdmin';
	public static Admin = 'Admin';
	public static User = 'User';
}

export interface AuthContextType {
	user: CI.ApiUserDto | null;
	appState: CI.AppStateDto | null;
	userAuthed: boolean;
	loading: boolean;
	error?: any;
	signIn: (request: CI.SignInDto, redirect: (passedAppState: CI.AppStateDto) => string) => void;
}

export function hasRole(user: CI.ApiUserDto | null, roleName: string | string[], appRoles: CI.RoleDto[] | null | undefined): boolean {
	if (!user)
		return false;

	var currentUserRoleNames = (appRoles ?? []).filter(x => (user.roles ?? []).indexOf(x.id) > -1).map(x => x.name.toLocaleLowerCase());

	if (Array.isArray(roleName)) {
		for (var rn of roleName) {
			if (currentUserRoleNames.indexOf(rn.toLocaleLowerCase()) > -1) {
				return true; // User has at least one of the roles in "roleName" list
			}
		}
	} else {
		return currentUserRoleNames.indexOf(roleName.toLocaleLowerCase()) > -1;
	}

	return false;
}

export const AuthContext = createContext<AuthContextType>(
	{} as AuthContextType
);

// Based on https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
	const snackbarRef = useSnackbar();
	const [user, setUser] = useState<CI.ApiUserDto | null>(null);
	const [userAuthed, setUserAuthed] = useState(window.localStorage.getItem('Authed') === 'true');
	const [error, setError] = useState<any>();
	const [loading, setLoading] = useState(false);

	const { data: appState, refetch: refetchAppState, isLoading: appStateLoading } = useQuery(['AppState'], async () => ApiClient.authV1AppState(), {
		cacheTime: 500, // milliseconds
		onError: (error) => {
			console.log("QC error", error);
		}
	});
	const signInRequest = useMutation((signInDto: CI.SignInDto) => {
		return ApiClient.authV1SignIn(signInDto);
	}, {
		onSuccess: () => {
			refetchAppState();
			snackbarRef.enqueueSnackbar("Signed in!", { variant: "success" });
		}
	})

	// We are using `react-router` for this example,
	// but feel free to omit this or use the
	// router of your choice.
	const navigate = useNavigate();
	const location = useLocation();

	// If we change page, reset the error state.
	useEffect(() => {
		setError(null);
	}, [location.pathname]);

	useEffect(() => {
		if (!appStateLoading) {
			if (appState && appState.userAuthed) {
				setUser(appState.user);
				setUserAuthed(true);
				window.localStorage.setItem('Authed', 'true');
			} else {
				setUserAuthed(false);
				window.localStorage.setItem('Authed', 'false');
				setUser(null);
			}
		}
	}, [appState, appStateLoading]);

	// Flags the component loading state and posts the sign in
	// data to the server.
	//
	// An error means that the email/password combination is
	// not valid.
	//
	// Finally, just signal the component that loading the
	// loading state is over.
	const signIn = useCallback(async (request: CI.SignInDto, newRoute: (passedAppState: CI.AppStateDto) => string) => {
		setLoading(true);

		try {
			const signInResult = await signInRequest.mutateAsync(request);
			if (signInResult.userAuthed) {
				setUserAuthed(true);
				window.localStorage.setItem('Authed', 'true');
				setUser(signInResult.user);
				setLoading(false);
				navigate(newRoute(signInResult), { replace: true });
			}
		} catch (ex) {
			console.error(ex);
		}
		setLoading(false);
	}, [navigate, signInRequest]);

	// Make the provider update only when it should.
	// We only want to force re-renders if the user,
	// loading or error states change.
	//
	// Whenever the `value` passed into a provider changes,
	// the whole tree under the provider re-renders, and
	// that can be very costly! Even in this case, where
	// you only get re-renders when logging in and out
	// we want to keep things very performant.
	const memoedValue = useMemo(
		() => ({
			user,
			appState: appState ?? null,
			userAuthed,
			loading,
			error,
			signIn,
		}),
		[user, appState, loading, error, userAuthed, signIn]
	);

	// We only want to render the underlying app after we
	// assert for the presence of a current user.
	return (
		<AuthContext.Provider value={memoedValue}>
			{!appStateLoading && children}
		</AuthContext.Provider>
	);
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
	return useContext(AuthContext);
}
