import { FC } from 'react';
import { Button } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';

interface IProps  {
	onAdd: () => void;
}

const DataGridCustomToolbar: FC<IProps> = (props: IProps) => {
	return (
		<GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
			<GridToolbarQuickFilter
				quickFilterParser={(searchInput) =>
					searchInput.split(',').map((value) => value.trim())
				}
				quickFilterFormatter={(quickFilterValues) => quickFilterValues?.join(', ') ?? ''}
				debounceMs={200} // time before applying the new quick filter value
			/>
			<Button variant="outlined" onClick={() => props.onAdd()}>Add</Button>
		</GridToolbarContainer>
	);
};

export default DataGridCustomToolbar;
