import { Box } from "@mui/material";
import Container from "@mui/system/Container";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid-pro";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import DataGridCustomToolbar from "../components/dataGridCustomToolbar";
import { DialogHelper } from "../components/dialogHelper";
import { AddEditTankerForm } from "../forms/addEditTankerForm";
import { ApiClient, CI } from "../services";
import { DeleteIcon, EditIcon } from "../shared/muiIcons";
import { field } from "../shared/utils";

export function TankerList() {
	const [edittankerId, setEditTankerId] = useState<string | null>();
	const { data: tankers, isLoading, refetch: reloadtankers } = useQuery(['tankersList'], async () => ApiClient.tankerV1List());
	const addtankerMutation = useMutation((newTanker: CI.TankerAddDto) => { return ApiClient.tankerV1Add(newTanker); });
	const edittankerMutation = useMutation((editTanker: CI.TankerDto) => { return ApiClient.tankerV1Edit(editTanker); });
	const deleteTankerMutation = useMutation((tankerId: string) => { return ApiClient.tankerV1Delete(tankerId); });

	const deleteTanker = useCallback(async (id: string) => {
		await deleteTankerMutation.mutateAsync(id);
		reloadtankers();
	}, [deleteTankerMutation, reloadtankers]);

	const columns = useMemo<GridColumns<CI.TankerDto>>(() => [
		{
			field: field<CI.TankerDto>().code,
			headerName: 'Code',
			flex: 2,
			minWidth: 100,
		},
		{
			field: 'actions',
			type: 'actions',
			width: 120,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<EditIcon />}
					label="Edit"
					onClick={() => setEditTankerId(params.row.id)}
				/>,
				<GridActionsCellItem
					icon={<DeleteIcon />}
					label="Delete"
					onClick={() => deleteTanker(params.row.id)}
					showInMenu
				/>,
			],
		},
	], [deleteTanker]);

	return (
		<Container disableGutters>
			<Box justifyItems={'center'} display="flex" alignItems="center" flexDirection='column' height={'100%'}>
				<h1>Tankers</h1>

				<div style={{ height: 400, width: '100%' }}>
					<DataGridPro
						rows={tankers ?? []}
						columns={columns}
						disableSelectionOnClick
						loading={isLoading || deleteTankerMutation.isLoading || addtankerMutation.isLoading}
						disableMultipleColumnsFiltering
						disableMultipleSelection
						disableColumnSelector
						disableColumnPinning
						disableColumnResize
						components={{
							Toolbar: () => <DataGridCustomToolbar onAdd={() => setEditTankerId(null)} />,
						}}
					/>
				</div>

				<DialogHelper
					title={edittankerId === null ? 'Add Tanker' : 'Edit Tanker'}
					open={edittankerId !== undefined}
				>
					<AddEditTankerForm
						tankerId={edittankerId ?? null}
						autoFocus={true}
						hideOptional={true}
						onCancel={() => setEditTankerId(undefined)}
						onAdd={async (data: CI.TankerAddDto) => {
							await addtankerMutation.mutateAsync(data);
							setEditTankerId(undefined);
							await reloadtankers();
							
						}} 
						onEdit={async (data: CI.TankerDto) => {
							await edittankerMutation.mutateAsync(data);
							setEditTankerId(undefined);
							await reloadtankers();	
						}}
						/>
				</DialogHelper>
			</Box>
		</Container>
	);
}
