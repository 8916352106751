import { Box } from "@mui/material";
import Container from "@mui/system/Container";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid-pro";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import DataGridCustomToolbar from "../components/dataGridCustomToolbar";
import { DialogHelper } from "../components/dialogHelper";
import { AddEditUserForm } from "../forms/addEditUserForm";
import { ApiClient, CI } from "../services";
import { DeleteIcon, EditIcon } from "../shared/muiIcons";
import { field } from "../shared/utils";

export function UserList() {
	const [editUserId, setEditUserId] = useState<string | null>();
	const { data: users, isLoading, refetch: reloadUsers } = useQuery(['UsersList'], async () => ApiClient.apiUserV1List());
	const addUserMutation = useMutation((newUser: CI.AddApiUserDto) => { return ApiClient.apiUserV1Add(newUser); });
	const editUserMutation = useMutation((editUser: CI.EditApiUserDto) => { return ApiClient.apiUserV1Edit(editUser); });
	const deleteUserMutation = useMutation((userId: string) => { return ApiClient.apiUserV1Delete(userId); });

	const deleteUser = useCallback(async (id: string) => {
		await deleteUserMutation.mutateAsync(id);
		reloadUsers();
	}, [deleteUserMutation, reloadUsers]);

	const columns = useMemo<GridColumns<CI.IApiUserSummaryDto>>(() => [
		{
			field: field<CI.IApiUserSummaryDto>().userName,
			headerName: 'Username',
			flex: 2,
			minWidth: 100,
		},
		{
			field: field<CI.IApiUserSummaryDto>().email,
			headerName: 'Email',
			flex: 2,
			minWidth: 100,
		},
		{
			field: 'actions',
			type: 'actions',
			width: 120,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<EditIcon />}
					label="Edit"
					onClick={() => setEditUserId(params.row.id)}
				/>,
				<GridActionsCellItem
					icon={<DeleteIcon />}
					label="Delete"
					onClick={() => deleteUser(params.row.id)}
					showInMenu
				/>,
			],
		},
	], [deleteUser]);

	return (
		<Container disableGutters>
			<Box justifyItems={'center'} display="flex" alignItems="center" flexDirection='column' height={'100%'}>
				<h1>Users</h1>

				<div style={{ height: 400, width: '100%' }}>
					<DataGridPro
						rows={users ?? []}
						columns={columns}
						disableSelectionOnClick
						loading={isLoading || deleteUserMutation.isLoading || addUserMutation.isLoading}
						disableMultipleColumnsFiltering
						disableMultipleSelection
						disableColumnSelector
						disableColumnPinning
						disableColumnResize
						components={{
							Toolbar: () => <DataGridCustomToolbar onAdd={() => setEditUserId(null)} />,
						}}
					/>
				</div>

				<DialogHelper
					title={editUserId === null ? 'Add user' : 'Edit user'}
					open={editUserId !== undefined}
				>
					<AddEditUserForm
						userId={editUserId ?? null}
						autoFocus={true}
						hideOptional={true}
						onCancel={() => setEditUserId(undefined)}
						onAdd={async (data: CI.AddApiUserDto) => {
							await addUserMutation.mutateAsync(data);
							setEditUserId(undefined);
							await reloadUsers();
							
						}} 
						onEdit={async (data: CI.EditApiUserDto) => {
							await editUserMutation.mutateAsync(data);
							setEditUserId(undefined);
							await reloadUsers();	
						}}
						/>
				</DialogHelper>
			</Box>
		</Container>
	);
}
