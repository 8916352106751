import Box, { BoxProps } from '@mui/material/Box';
import React, { FC, PropsWithChildren } from 'react';

import './horizontalBox.scss';

interface IProps extends BoxProps {

}

const HorizontalBox: FC<PropsWithChildren<IProps>> = (props: PropsWithChildren<IProps>) => {
  return (
	<Box {...props} className='horizontal-box'>
		{props.children}
    </Box>
  );
};

export default HorizontalBox;