import { Alert, Box, Link } from "@mui/material";

function Home() {

	return (
		<Box justifyItems={'center'} display="flex" alignItems="center" flexDirection='column' height={'100%'}>
			<h1>Tanker coverage monitor!</h1>
			<p>This is a web app designed to help visualise cell coverage of Fonterra phones on mil tankers</p>
			<p>Please send any feedback and suggestions to <Link href={'support_general@torutek.com'}>support_general@torutek.com</Link></p>
		</Box>
	);
}

export default Home;
