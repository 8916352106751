import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { PropsWithChildren } from "react";


interface DialogHelperProps {
	open: boolean;
	title: string;
	subTitle?: string;
	onCancel?: () => void;
	onOk?: () => void;
	//children: (open: boolean, toggleOpen: () => void) => React.ReactNode;
}

export function DialogHelper(props: PropsWithChildren<DialogHelperProps>): JSX.Element {
	//	const [open, setOpen] = useState(false);

	const handleClose = () => {
	};

	return (
		<Dialog 
			open={props.open}
			onClose={handleClose}
		>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent style={{minWidth: '280px', minHeight: '200px', display: 'flex'}}>
				{props.subTitle && <DialogContentText> {props.subTitle} </DialogContentText>}
				{props.children}		
			</DialogContent>
			{(props.onCancel || props.onOk) && <DialogActions>
				{props.onCancel && <Button onClick={props.onCancel}>Cancel</Button>}
				{props.onOk && <Button onClick={props.onOk}>Ok</Button>}
			</DialogActions>}
		</Dialog>
	)
}