import LoadingButton from "@mui/lab/LoadingButton";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller, Resolver } from "react-hook-form";
import { ApiClient, CI } from "../services";
import { EnumList, field, ValidatorObj } from "../shared/utils";
import { FormMode } from "./formHelpers";
import Button from "@mui/material/Button";
import HorizontalBox from "../components/horizontalBox";
import { useQuery } from "react-query";
import { Autocomplete, Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import './form.scss';

interface AddEditGroupFormProps {
	groupId: string | null;
	autoFocus?: boolean;
	submitText?: string;
	hideOptional?: boolean;
	onCancel?: () => void;
	onAdd?: (data: CI.TankerGroupDto) => void;
	onEdit?: (data: CI.TankerGroupDto) => void;
}

const defaultValues: CI.TankerGroupDto = new CI.TankerGroupDto({
	id: '',
	name: '',
	tankers: []
});

const validator: Resolver<CI.TankerGroupDto, object> = (values: CI.TankerGroupDto) => {
	var errors = ValidatorObj<CI.TankerGroupDto>();
	if (!values.name || values.name.length < 1)
		errors.name = { message: 'code required' };

	return {
		errors,
		values: values,
	};
};

export function AddEditTankerGroupForm(props: AddEditGroupFormProps) {
	const { data: existingGroup, isLoading: tankerGroupLoading } = useQuery(['GetGroup', props.groupId], async () => ApiClient.tankerGroupV1Get(props.groupId!, false), {
		enabled: !!props.groupId
	});

	const { handleSubmit, reset: resetForm, control, formState: { errors } } = useForm<CI.TankerGroupDto>({
		defaultValues: defaultValues,
		mode: 'onChange',
		resolver: validator
	});
	const [submitting, setSubmitting] = useState(false);
	const mode: FormMode = !props.groupId ? 'Add' : 'Update';

	useEffect(() => {
		if (props.groupId && existingGroup) {
			resetForm(existingGroup);
		} else {
			resetForm(defaultValues);
		}

		return () => {
			setSubmitting(false);
			resetForm(defaultValues);
		}
	}, [resetForm, props.groupId, existingGroup]);

	const onSubmit: SubmitHandler<CI.TankerGroupDto> = async (data: CI.TankerGroupDto) => {
		setSubmitting(true);

		try {
			if (mode === 'Update') {
				props.onEdit && await props.onEdit(data);
			} else {
				props.onAdd && await props.onAdd(data);
			}
		} catch (ex) {
			console.error(ex);
		} finally {
			setSubmitting(false);
		}
	};

	if (tankerGroupLoading) {
		return <Box sx={{ margin: 'auto' }}>
			<CircularProgress />
		</Box>
	}

	return (
		//Form example https://codesandbox.io/s/react-hook-form-controller-601-j2df5
		<form className='torutek-form' onSubmit={handleSubmit(onSubmit)}>
			<section>
				<Controller
					render={({ field }) => <TextField
						{...field}
						autoFocus={props.autoFocus}
						label='Group name'
						inputProps={{
							value: field.value ?? ''
						}}
						required
						error={!!errors.name?.message}
						helperText={errors.name?.message}
					/>}
					name={field<CI.TankerGroupDto>().name}
					control={control}
				/>
			</section>

			<HorizontalBox>
				<LoadingButton variant="contained" type="submit" loading={submitting}>{props.submitText ?? mode}</LoadingButton>
				{props.onCancel && <Button variant="outlined" type="button" onClick={props.onCancel} >Cancel</Button>}
			</HorizontalBox>
		</form>
	);
}
