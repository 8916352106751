import LoadingButton from "@mui/lab/LoadingButton";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller, Resolver } from "react-hook-form";
import { ApiClient, CI } from "../services";
import { EnumList, field, ValidatorObj } from "../shared/utils";
import { FormMode } from "./formHelpers";
import Button from "@mui/material/Button";
import HorizontalBox from "../components/horizontalBox";
import { useQuery } from "react-query";
import { Autocomplete, Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import './form.scss';

interface AddEditTankerFormProps {
	tankerId: string | null;
	autoFocus?: boolean;
	submitText?: string;
	hideOptional?: boolean;
	onCancel?: () => void;
	onAdd?: (data: CI.TankerDto) => void;
	onEdit?: (data: CI.TankerDto) => void;
}

const defaultValues: CI.TankerDto = new CI.TankerDto({
	id: '',
	code: '',
	groups: [],
});

const validator: Resolver<CI.TankerDto, object> = (values: CI.TankerDto) => {
	var errors = ValidatorObj<CI.TankerDto>();
	if (!values.code || values.code.length < 1)
		errors.code = { message: 'code required' };

	return {
		errors,
		values: values,
	};
};

export function AddEditTankerForm(props: AddEditTankerFormProps) {
	const { data: existingTanker, isLoading: userLoading } = useQuery(['GetTanker', props.tankerId], async () => ApiClient.tankerV1Get(props.tankerId!), {
		enabled: !!props.tankerId
	});

	const { data: groups, isLoading: groupsLoading } = useQuery(['TankerGroupsList'], async () => ApiClient.tankerGroupV1List());

	const { handleSubmit, reset: resetForm, control, formState: { errors } } = useForm<CI.TankerDto>({
		defaultValues: defaultValues,
		mode: 'onChange',
		resolver: validator
	});
	const [submitting, setSubmitting] = useState(false);
	const mode: FormMode = !props.tankerId ? 'Add' : 'Update';

	useEffect(() => {
		if (props.tankerId && existingTanker) {
			resetForm(existingTanker);
		} else {
			resetForm(defaultValues);
		}

		return () => {
			setSubmitting(false);
			resetForm(defaultValues);
		}
	}, [resetForm, props.tankerId, existingTanker]);

	const onSubmit: SubmitHandler<CI.TankerDto> = async (data: CI.TankerDto) => {
		setSubmitting(true);

		try {
			if (mode === 'Update') {
				props.onEdit && await props.onEdit(data);
			} else {
				props.onAdd && await props.onAdd(data);
			}
		} catch (ex) {
			console.error(ex);
		} finally {
			setSubmitting(false);
		}
	};

	if (userLoading || groupsLoading) {
		return <Box sx={{ margin: 'auto' }}>
			<CircularProgress />
		</Box>
	}

	return (
		//Form example https://codesandbox.io/s/react-hook-form-controller-601-j2df5
		<form className='torutek-form' onSubmit={handleSubmit(onSubmit)}>
			<section>
				<Controller
					render={({ field }) => <TextField
						{...field}
						autoFocus={props.autoFocus}
						label='Code'
						inputProps={{
							value: field.value ?? ''
						}}
						required
						error={!!errors.code?.message}
						helperText={errors.code?.message}
					/>}
					name={field<CI.TankerDto>().code}
					control={control}
				/>
			</section>

			<section>
				<Controller
					control={control}
					name={field<CI.TankerDto>().groups}
					rules={{ required: true }}
					render={({ field: { onChange, value } }) => (
						<Autocomplete
							multiple={true}
							onChange={(_, items: CI.TankerGroupDto[] | null) => {
								items && onChange(items.map(x => x.id));
							}}
							// value={(transportOptions && value) ? transportOptions.filter(x => value.indexOf(x.id) > -1) : []}
							value={(groups && value) ? groups.filter(x => value.indexOf(x.id) > -1) : []}
							options={groups ?? []}
							getOptionLabel={(item: CI.TankerGroupDto) => (item.name ?? '')}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Groups"
									error={!!errors.groups?.message}
									helperText={errors.groups?.message}
								/>
							)}
						/>
					)}
				/>
			</section>

			<HorizontalBox>
				<LoadingButton variant="contained" type="submit" loading={submitting}>{props.submitText ?? mode}</LoadingButton>
				{props.onCancel && <Button variant="outlined" type="button" onClick={props.onCancel} >Cancel</Button>}
			</HorizontalBox>
		</form>
	);
}
