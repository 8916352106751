// https://date-fns.org/docs/Getting-Started
// https://date-fns.org/v1.1.0/docs/format
import format from 'date-fns/format'
import { useLayoutEffect, useState } from 'react';
import { CI } from '../services';

export const longDateFormat = 'iii, d MMM yyyy';
export const mediumDateFormat = 'MMM d, yyyy';
export const shortDateFormat = 'd MMM yyyy';
export const dateFormatWithDay = 'E, do MMM yyyy'; // Wed, 3rd Mar 2022
export const tinyDateFormat = 'd MMM';
export const longTimeFormat = 'h:mm a';
export const shortTimeFormat = 'h:mm a';
export const shortDateTimeFormat = shortDateFormat + ' ' + shortTimeFormat;


function dateFormatL(sourceDate: Date | null | undefined, dateFormat: string, timeFormat: string, includeDate: boolean, includeTime: boolean): string {
	if (!sourceDate) {
		return '';
	}

	var dateTimeFormat = '';
	dateTimeFormat += includeDate ? dateFormat : '';
	dateTimeFormat += (includeDate && includeTime) ? ', ' : '';
	dateTimeFormat += includeTime ? timeFormat : '';

	return format(sourceDate, dateTimeFormat);
}

// Nice collection of contrasting colors
export const colors = ["#045459","#087353","#15C286","#ABD96D","#FBBF54","#EE6B3B","#EC0F47","#A02C5D","#700460","#022C7A","#1A1333","#262949"];

const regex = /^\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2}:\d{2} [ap]m [+-]\d{2}:\d{2}$/i;

export function isDateString(str: string | null | undefined): boolean {
	if (!str)
		return false;

	return regex.test(str);
}
export function dateTimeExcel(sourceDate: Date | null | undefined): string {
	if (!sourceDate) {
		return '';
	}

	return format(sourceDate, 'yyyy-MM-dd HH:mm:ss');
}

export function dateTimeL(sourceDate: Date | null | undefined): string {
	return dateFormatL(sourceDate, longDateFormat, longTimeFormat, true, true)
}
export function dateTimeS(sourceDate: Date | null | undefined): string {
	return dateFormatL(sourceDate, shortDateFormat, shortTimeFormat, true, true)
}
// 21 feb, 2:45 pm
export function dateTimeT(sourceDate: Date | null | undefined): string {
	return dateFormatL(sourceDate, tinyDateFormat, shortTimeFormat, true, true)
}
export function dateL(sourceDate: Date | null | undefined): string {
	return dateFormatL(sourceDate, longDateFormat, '', true, false)
}
export function dateM(sourceDate: Date | null | undefined): string {
	return dateFormatL(sourceDate, mediumDateFormat, '', true, false)
}
export function dateDay(sourceDate: Date | null | undefined): string {
	return dateFormatL(sourceDate, dateFormatWithDay, '', true, false)
}
export function dateS(sourceDate: Date | null | undefined): string {
	return dateFormatL(sourceDate, shortDateFormat, '', true, false)
}
export function timeL(sourceDate: Date | null | undefined): string {
	return dateFormatL(sourceDate, '', longTimeFormat, false, true)
}
export function timeS(sourceDate: Date | null | undefined): string {
	return dateFormatL(sourceDate, '', shortTimeFormat, false, true)
}

export function ValidationObj<TSource>() {
	type ErrorType<T extends TSource> = Record<keyof T, string>;
	let errors = {} as ErrorType<TSource>;
	return errors;
}

interface ValidatorError {
	message: string;
}

export function ValidatorObj<TSource>() {
	type ErrorType<T extends TSource> = Record<keyof T, ValidatorError>;
	let errors = {} as ErrorType<TSource>;
	return errors;
}



export function field<T>() {
	return new Proxy(
		{},
		{
			get: function (_target, prop, _receiver) {
				return prop;
			},
		}
	) as {
			[P in keyof T]: P;
		};
};

export function newGuid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0,
			v = c === 'x' ? r : ((r & 0x3) | 0x8);
		return v.toString(16);
	});
}

interface EnumKeyPairOveride {
	id: string;
	label: string;
}

export function EnumList<T extends {}>(type: T, valueOverrides: EnumKeyPairOveride[] = []): { id: string; label: string; }[] {
	const keys = Object.keys(type);

	return keys.map(s => {
		let newValue = type[s as (keyof typeof type)] + '';
		for (let override of valueOverrides) {
			if ((newValue) === override.id) {
				newValue = override.label;
			}
		}

		return {
			id: s,
			label: newValue
		};
	})
}

export function getErrorMessage(error: any): string {
	let errorMessage = 'Unknown error';

	if (error instanceof (CI.ProblemDetails)) {
		var problemDetails = error as CI.ProblemDetails;
		errorMessage = problemDetails.title ?? 'Unknown error';
	} else if (error.response && (error.response as string).indexOf('title') > -1) {
		var torutekErrors = JSON.parse(error.response) as CI.ProblemDetails;
		errorMessage = torutekErrors.title ?? '?';
		console.error(torutekErrors);
	} else if (error && error.errors && error.errors[0]) {
		errorMessage = error.errors[0];
		if (errorMessage.startsWith('The JSON value could not be converted to')) {
			console.error(errorMessage);
			errorMessage = 'malformed request. Invalid model';
		}
	} else if (error && error.status) {
		if (error.status === 400) {
			errorMessage = 'Bad request';
		} else if (error.status === 401) {
			errorMessage = 'Sign in required';
		} else if (error.status === 403) {
			errorMessage = 'Permission denied';
		} else if (error.status === 500) {
			errorMessage = 'Server error, contact support';
		} else {
			errorMessage += ':' + error.status
		}
	} else if (error && error.message) {
		errorMessage = error.message;
	}

	if (errorMessage === 'Failed to fetch') {
		errorMessage = 'unable to reach server';
	}

	return errorMessage;
}

export function useWindowSize() {
	const [size, setSize] = useState([window.innerWidth ?? 0, window.innerHeight ?? 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
}