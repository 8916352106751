import { Route, Routes as RouteWrapper, Navigate } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import { useTheme } from "@mui/material/styles";
import useAuth, { hasRole, Roles } from "./shared/useAuth";
import ApiIcon from '@mui/icons-material/Api';
import Home from './pages/home';
import SignIn from './pages/signin';
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { MapIcon, MovingIcon, PeopleAltIcon, LocalShippingIcon, GroupWorkIcon } from "./shared/muiIcons";
import { UserList } from "./pages/userList";
import { MapView } from "./pages/mapView";
import { TankerList } from "./pages/tankerList";
import { TankerGroupList } from "./pages/tankerGroupList";

export interface IRouteChange {
	onRouteClick: () => void;
}

export interface NavLinks {
	route: string;
	name: string;
	icon: JSX.Element;
	loginRequired: boolean;
	requiredRole?: string[];
}

export enum Routes {
	Home = '/',
	SignIn = '/sign-in',
	SignInMessage = '/sign-in/:message',	
	UserList = '/users/list',
	TankerList = '/tankers/list',
	TankerGroupList = '/tanker-groups/list',
	MapView = '/map',	
	MapDateView = '/map/:date'
}

// https://betterprogramming.pub/the-best-way-to-manage-routes-in-a-react-project-with-typescript-c4e8d4422d64
type RouteArgs =
	| { path: Routes.Home }
	| { path: Routes.SignIn }
	| { path: Routes.SignInMessage, params: { message: string } }
	| { path: Routes.UserList }
	| { path: Routes.TankerList }
	| { path: Routes.TankerGroupList }
	| { path: Routes.MapView, params: { date?: string};}	

type RouteArgsWithParams = Extract<RouteArgs, { path: any; params: any }>;

export function getRoute(args: RouteArgs) {
	if (args.hasOwnProperty('params') === false) return args.path;

	// Create a path by replacing params in the route definition
	return Object.entries((args as RouteArgsWithParams).params).reduce(
		(previousValue: string, [param, value]) =>
			previousValue.replace(`:${param}`, '' + value),
		args.path
	);
}

export function RouterDefinition() {
	return (
		<RouteWrapper>
			<Route path={Routes.SignIn} element={<SignIn />} />
			<Route path={Routes.SignInMessage} element={<SignIn />} />
			<Route path={Routes.Home} element={<Home />} />
			<Route path={Routes.UserList} element={<UserList />} />
			<Route path={Routes.TankerList} element={<TankerList />} />
			<Route path={Routes.TankerGroupList} element={<TankerGroupList />} />
			<Route path={Routes.MapView} element={<MapView />} />			
			<Route path={Routes.MapDateView} element={<MapView />} />
			<Route path="*" element={<Navigate to="/" />} />
		</RouteWrapper>
	);
}

const routes: NavLinks[] = [
	{ route: Routes.Home, name: 'Welcome', loginRequired: false, icon: <HomeIcon /> },
	{ route: Routes.UserList, name: 'Users', requiredRole: [Roles.GlobalAdmin, Roles.Admin], loginRequired: true, icon: <PeopleAltIcon /> },
	{ route: Routes.TankerList, name: 'Tankers', requiredRole: [Roles.GlobalAdmin, Roles.Admin], loginRequired: true, icon: <LocalShippingIcon /> },
	{ route: Routes.TankerGroupList, name: 'Groups', requiredRole: [Roles.GlobalAdmin, Roles.Admin], loginRequired: true, icon: <GroupWorkIcon /> },
	{ route: Routes.MapView, name: 'Map', loginRequired: true, icon: <MapIcon /> },
]

export function RouteMenuLinks(props: IRouteChange) {
	const theme = useTheme();
	const { user, userAuthed, appState } = useAuth();
	const signOutUrl = process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_API_URL}/api/Auth/v1/sign-out` : `/api/Auth/v1/sign-out`

	return (<>
		<List>
			{routes.filter(x => (!x.loginRequired || userAuthed) && (!x.requiredRole || hasRole(user, x.requiredRole, appState?.roles))).map((navLink, index) => (
				<RouterLink key={index} to={navLink.route} onClick={() => props.onRouteClick()} style={{ textDecoration: 'none' }}>
					<ListItem button key={navLink.name}>
						<ListItemIcon>
							{navLink.icon}
						</ListItemIcon>
						<ListItemText primary={navLink.name} sx={{ color: theme.palette.text.primary }} />
					</ListItem>
				</RouterLink>
			))}

			{window.location.href.indexOf("localhost") > -1 && <a href={`${process.env.REACT_APP_API_URL}/swagger/index.html`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
				<ListItem button>
					<ListItemIcon>
						<ApiIcon />
					</ListItemIcon>
					<ListItemText primary={'Swagger Api'} sx={{ color: theme.palette.text.primary }} />
				</ListItem>
			</a>}

			{!userAuthed && <RouterLink to={'/sign-in'} onClick={() => props.onRouteClick()} style={{ textDecoration: 'none' }}>
				<ListItem button key={'Sign in'}>
					<ListItemIcon>
						<LoginIcon />
					</ListItemIcon>
					<ListItemText primary={'Sign in'} sx={{ color: theme.palette.text.primary }} />
				</ListItem>
			</RouterLink>}

			{userAuthed && <a href={signOutUrl} onClick={() => props.onRouteClick()} style={{ textDecoration: 'none' }}>
				<ListItem button>
					<ListItemIcon>
						<LogoutIcon />
					</ListItemIcon>
					<ListItemText primary={'Sign out'} sx={{ color: theme.palette.text.primary }} />
				</ListItem>
			</a>}
		</List>
	</>)
}

