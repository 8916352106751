import { LinearProgress, Table, TableBody, TableCell, TableRow, TextField, Box, Slider } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { DatePicker } from '@mui/x-date-pickers';
import mapboxgl, { MapLayerMouseEvent } from 'mapbox-gl';
import React, { ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MapBoxMap, { Layer, Source, ViewStateChangeEvent } from 'react-map-gl';
import { useQuery } from 'react-query';
import { NetworkType, PhoneState } from '../services/nswagClient';
import { EnumList } from '../shared/utils';
import { LayerControl, LayerOption, MapLayer, MapStyleChoice, StyleOption } from './layerControl';
import { regionPolyLayer, regionLineLayer } from './mapStyles';

import './t2Map.scss';
import { TankerGroupControl } from './tankerGroupControl';
import { ApiClient } from '../services/index';
import { useParams, useNavigate } from 'react-router-dom';
import CentredLoading from '../shared/centredLoading';
import { endOfDay, format, subMinutes } from 'date-fns';

//https://partiellkorrekt.de/2021/03/23/extract-the-proptypes-from-a-react-component-in-typescript/
type ExtractProps<T> = T extends React.Component<infer P> | React.FC<infer P> ? P : never;

export type T2MapOptions = {
	mapId: string;
	loading?: boolean;
	overlays?: ReactNode;
};

type MapWrapperProps = T2MapOptions & ExtractProps<typeof MapBoxMap>;

const cleanUpIds = ['nearby-arrows'];
const layersDefault: LayerOption[] = [
	{ name: "Regions", checked: false },
	{ name: "Topography (Zoomed in)", checked: false },
	{ name: "Time slider", checked: true },
	{ name: "Farm vats (Standard, Zoomed in)", checked: false }
];

const stylesDefault: StyleOption[] = [
	{ name: "Satellite", checked: false },
	{ name: 'Satellite Streets', checked: false},
	{ name: "Standard", checked: true },
	{ name: "Street", checked: false },	
];



// https://visgl.github.io/react-map-gl/docs/api-reference/use-map
export const T2Map = (props: MapWrapperProps) => {
	const { onLoad, interactiveLayerIds, onClick, onMouseMove, ...rest } = props;
	const tileServer = window.location.origin;
	const theme = useTheme();
	const mapRef = useRef<mapboxgl.Map>();
	const [selectedTankers, setSelectedTankers] = useState<string[]>([]);
	const [cursor, setCursor] = useState<string>('auto');


	//5760 is the number of minutes in 4 days (24*60*4) - change this if we want slider to change
	const timeSliderRange = 5760;


	const [timeSliderValue, setTimeSliderValue] = useState<number[]>([0, timeSliderRange]);
	const [timeSliderCommittedValue, setTimeSliderCommittedValue] = useState<number[]>([0, timeSliderRange]);

	const savedMapZoomState = window.localStorage.getItem('zoom');
	const [zoomLevel, setZoomLevel] = useState(savedMapZoomState ? JSON.parse(savedMapZoomState) : 11);
	const savedMapLatState = window.localStorage.getItem('latitude');
	const [latitude, setLatitude] = useState(savedMapLatState ? JSON.parse(savedMapLatState) : -38.7860668);

	const savedMapLonState = window.localStorage.getItem('longitude');
	const [longitude, setLongitude] = useState(savedMapLonState ? JSON.parse(savedMapLonState) : 175.892910);

	const [hoveredRegion, setHoveredRegion] = useState('');
	const [hoveredPoint, setHoveredPoint] = useState(<div></div>);
	const [hoveredFarm, setHoveredFarm] = useState(<div></div>)
	const savedMapLayerState = window.localStorage.getItem('MapLayers');
	const savedMapLayerStateObj = (savedMapLayerState ? JSON.parse(savedMapLayerState) : null) as LayerOption[] | null;
	const savedMapStyleState = window.localStorage.getItem('MapStlyes');
	const savedMapStyleStateObj = (savedMapStyleState ? JSON.parse(savedMapStyleState) : null) as StyleOption[] | null;


	const [signalStrength, setSignalStrength] = useState<number[]>([0, 4]);
	const [latency, setLatency] = useState<number[]>([0, 1000]);
	const [showData, setShowData] = useState(false);

	let navigate = useNavigate();
	const urlDate = useParams<{ date: string }>();
	const [currentDate, setCurrentDate] = React.useState<Date | null>(null);
	const [filename, setFilename] = useState<string>();

	const { data: historicDates, isLoading: datesLoading } = useQuery(['list-tile-files'], async () => ApiClient.listTileFiles(), { cacheTime: 1000 * 60 * 5 });
	const { isLoading: downloadingTiles } = useQuery(['ensure-' + filename + '-downloaded'], async () => ApiClient.tileDataV1EnsureDownloaded(filename), { enabled: !!filename });

	useEffect(() => {
		if (urlDate.date !== undefined) {
			setFilename(urlDate.date);
			let properTime = endOfDay(new Date(urlDate.date));
			setCurrentDate(properTime);
		} else {
			if (historicDates && historicDates.blobList) {
				const blobList = historicDates.blobList;
				if (blobList.length > 0 && blobList[0]) {
					if (blobList[0]!.blobName !== undefined) {
						setFilename(blobList[0]!.blobName!.split('.')[0]);
						setCurrentDate(new Date(blobList[0]!.blobName!.split('.')[0]));
						navigate({ pathname: '/map/' + blobList[0]!.blobName!.split('.')[0] }, { replace: true, });
					}
				}
			}
		}
	}, [urlDate, historicDates, navigate])


	const [layers, setLayers] = useState<LayerOption[]>(layersDefault.map(e => {
		return {
			name: e.name,
			checked: savedMapLayerStateObj ? savedMapLayerStateObj?.find(x => x.name === e.name)?.checked === true : true
		}
	}));

	const [styles, setStyles] = useState<StyleOption[]>(stylesDefault.map(e => {
		return {
			name: e.name,
			checked: savedMapStyleStateObj ? savedMapStyleStateObj?.find(x => x.name === e.name)?.checked === true : false
		}
	}));

	const [mapStyle, setMapStyle] = useState<MapStyleChoice>("Standard");
	const [mapURL, setMapURL] = useState<string>();

	const showRegionLayer = layers.find(x => x.name === "Regions")?.checked;
	const showTopographyLayer = layers.find(x => x.name === "Topography (Zoomed in)")?.checked;
	const showSliderLayer = layers.find(x => x.name === "Time slider")?.checked;
	const showFarmVatsLayer = layers.find(x => x.name === "Farm vats (Standard, Zoomed in)")?.checked;

	const mapLayerIds = [...props.interactiveLayerIds ?? []];
	if (showRegionLayer) {
		mapLayerIds.push('region-poly-layer');
	}

	if (showFarmVatsLayer){
		mapLayerIds.push('farm-points');
	}

	const { data: nzRegions } = useQuery(['NzRegions'], async () => {
		const res = await fetch("/layers/nz-regions.geojson");
		return res.json();
	}, {
		enabled: showRegionLayer === true,
		cacheTime: 1000 * 60 * 60 * 24 * 7, // 7 days
	});

	const { data: farmVats } = useQuery(['FarmVats'], async () => {
		const res = await fetch("https://fonterratankercoverage.blob.core.windows.net/public/farm-vat-locations.geojson");
		return res.json();
	}, {
		enabled: showFarmVatsLayer === true,
		cacheTime: 1000 * 60 * 60 * 24, // 1 day
	});

	const resizeObserver = new ResizeObserver(() => {
		if (mapRef.current) {
			mapRef.current.resize();
		}
	});

	const getZoomLevelClass = useCallback(() => {
		// console.log("zoomLevel: " + zoomLevel);
		if (zoomLevel > 14) {
			return 'street-zoom';
		} else if (zoomLevel > 10) {
			return 'low-zoom'; // city size
		} else if (zoomLevel > 8.5) {
			return 'medium-zoom'; // half country
		} else if (zoomLevel > 6) {
			return 'high-zoom';
		} else {
			return 'space-zoom';
		}
	}, [zoomLevel]);

	useEffect(() => {
		window.localStorage.setItem('zoom', JSON.stringify(zoomLevel));
	}, [zoomLevel]);

	useEffect(() => {
		window.localStorage.setItem('MapLayers', JSON.stringify(layers));
	}, [layers]);

	useEffect(() => {
		window.localStorage.setItem('MapStyles', JSON.stringify(styles));
	}, [styles]);

	useEffect(() => {
		window.localStorage.setItem('latitude', JSON.stringify(latitude));
		window.localStorage.setItem('longitude', JSON.stringify(longitude))
	}, [latitude, longitude]);

	const onZoomEnd = useCallback((event: ViewStateChangeEvent) => {
		console.log(event.viewState.zoom);
		setZoomLevel(event.viewState.zoom)
	}, []);

	const onMoveEnd = useCallback((event: ViewStateChangeEvent) => {
		setLatitude(event.viewState.latitude);
		setLongitude(event.viewState.longitude);
	}, []);

	function onClickLayer(layerName: MapLayer) {
		let newLayers = [...layers];
		let toggledLayer = newLayers.find(x => x.name === layerName);
		if (toggledLayer) {
			toggledLayer.checked = !toggledLayer.checked;
			setLayers(newLayers);
		}
	}

	const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMapStyle((event.target as HTMLInputElement).value as MapStyleChoice);
	};

	const chooseStyle = useEffect(() => {
		if (mapStyle === "Satellite"){
			setMapURL('mapbox://styles/mapbox/satellite-v9');
		}else if (mapStyle === "Standard"){
			setMapURL(theme.palette.mode === 'dark' ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10');
		}else if (mapStyle === "Street"){
			setMapURL("mapbox://styles/mapbox/streets-v12");
		}else if (mapStyle === "Satellite Streets"){
			setMapURL("mapbox://styles/mapbox/satellite-streets-v12");
		}
	}, [mapStyle])


	// Show pointer because line is clickable
	const mouseMove = useCallback((event: MapLayerMouseEvent) => {
		// Get the feature currently hovering over
		if (event && event.features) {

			if (showRegionLayer) {
				let regionItems = event.features.filter(x => x.layer.id === 'region-poly-layer');
				if (regionItems.length > 0) {
					setHoveredRegion(regionItems[0].properties?.Name ?? '');
				} else {
					setHoveredRegion('');
				}
			} else {
				setHoveredRegion('');
			}

			let farmDataPoint = event.features.find(x => x.layer?.id === 'farm-points')
			if (farmDataPoint){
				let fi = farmDataPoint.properties as any
				setHoveredFarm(
					<Table size="small" aria-label="a dense table">
					<TableBody><TableRow><TableCell className='prop-name'>Farm id:</TableCell><TableCell align='left'>{fi.ID}</TableCell></TableRow></TableBody>
					</Table>
				)
			}else{
				setHoveredFarm(<div></div>)
			}

			let tankerDataPoint = event.features.find(x => x.layer?.id === 'vector-point');
			if (tankerDataPoint) {
				let ri = tankerDataPoint.properties as any;
				setHoveredPoint(
					<Table size="small" aria-label="a dense table">
						<TableBody>
							<TableRow><TableCell className='prop-name'>Id:</TableCell><TableCell align="left">{ri.id}</TableCell></TableRow>
							<TableRow><TableCell className='prop-name'>GPS accuracy:</TableCell><TableCell align="left">{ri.ga}m</TableCell></TableRow>

							<TableRow><TableCell className='prop-name'>Network type:</TableCell><TableCell align="left">{EnumList(NetworkType)[ri.nt]?.label}</TableCell></TableRow>
							<TableRow><TableCell className='prop-name'>Phone state:</TableCell><TableCell align="left">{EnumList(PhoneState)[ri.ps]?.label}</TableCell></TableRow>
							<TableRow><TableCell className='prop-name'>Signal strength:</TableCell><TableCell align="left">{ri.sr}/4</TableCell></TableRow>

							<TableRow><TableCell className='prop-name'>dBm:</TableCell><TableCell align="left">{ri.db}</TableCell></TableRow>
							<TableRow><TableCell className='prop-name'>Asu:</TableCell><TableCell align="left">{ri.as}</TableCell></TableRow>

							<TableRow><TableCell className='prop-name'>Data?:</TableCell><TableCell align="left">{ri.da === 0 ? 'No' : 'Yes'}</TableCell></TableRow>
							<TableRow><TableCell className='prop-name'>Latency:</TableCell><TableCell align="left">{ri.pt}ms</TableCell></TableRow>

							<TableRow><TableCell className='prop-name'>Time stamp:</TableCell><TableCell align="left">{ri.ts}</TableCell></TableRow>
						</TableBody>
					</Table>);
			} else {
				setHoveredPoint(<div></div>);
			}
		}

		if (event && event.features && event.features.length > 0) {
			setCursor('pointer');
		} else {
			setCursor('auto');
		}

		onMouseMove && onMouseMove(event);
	}, [onMouseMove, showRegionLayer]);

	const mouseClick = useCallback((event: MapLayerMouseEvent) => {
		// Get the feature currently hovering over
		if (event && event.features) {
			// let geoItemIndex = event.features.findIndex(x => x.layer.id === 'hw-symbol-layer' || x.layer.id === 'hw-line-layer');
			// if (geoItemIndex > -1) {
			// 	var eventCopy = Object.assign({} as MapLayerMouseEvent, event);
			// 	eventCopy.features = [...event.features.slice(geoItemIndex,1)];
			// }		
		}

		onClick && onClick(event);
	}, [onClick]);

	//filter: ["in", ['get', 'id'], ["literal", [780, 781]]], // works
	// https://docs.mapbox.com/help/glossary/filter/
	// OLD way https://docs.mapbox.com/mapbox-gl-js/style-spec/#other-filter
	const filter = useMemo(() => {
		var selectedTankerFilter;

		if (selectedTankers && selectedTankers.length > 0) {
			selectedTankerFilter = ["in", ['get', 'id'], ["literal", selectedTankers]]
		}
		else {
			selectedTankerFilter = ["has", 'id']; // Try for all
		}

		var lowerLatencyFilter;
		var upperLatencyFilter;
		if (latency[0] !== 0) {
			lowerLatencyFilter = [">=", ['get', 'pt'], latency[0]];
		}
		if (latency[1] !== 1000) {
			upperLatencyFilter = ["<=", ['get', 'pt'], latency[1]];
		}

		var selectedLatencyFilter;
		if (lowerLatencyFilter && upperLatencyFilter) {
			selectedLatencyFilter = ["all", upperLatencyFilter, lowerLatencyFilter];
		} else if (lowerLatencyFilter) {
			selectedLatencyFilter = lowerLatencyFilter;
		} else if (upperLatencyFilter) {
			selectedLatencyFilter = upperLatencyFilter;
		} else {
			selectedLatencyFilter = ["has", 'id'];
		}

		var selectedSignalStrength = ["all", [">=", ['get', 'sr'], signalStrength[0]], ["<=", ['get', 'sr'], signalStrength[1]]]

		var selectedShowData;
		if (showData) {
			selectedShowData = ["==", ['get', 'da'], 0];
		} else {
			selectedShowData = ["has", 'id']; // Try for all
		}

		var selectedTime;
		if (showSliderLayer) {
			if (currentDate) {
				//date end is the date we want to choose the maximum date from
				let dateEnd = subMinutes(currentDate, timeSliderRange - timeSliderCommittedValue[1]);
				let dateStart = subMinutes(currentDate, timeSliderRange - timeSliderCommittedValue[0]);
				selectedTime = ["all", ["<=", ['get', 'ts'], format(dateEnd, "yyyy-MM-dd HH:mm:ss")], [">=", ['get', 'ts'], format(dateStart, "yyyy-MM-dd HH:mm:ss")]];
			}

		} else {
			selectedTime = ["has", 'id']
		}

		return ["all", selectedSignalStrength, selectedTankerFilter, selectedLatencyFilter, selectedShowData, selectedTime];


	}, [selectedTankers, signalStrength, latency, showData, currentDate, showSliderLayer, timeSliderCommittedValue]);



	const showDatepicker = useCallback(() => {
		return (
			<div>
				<DatePicker
					className='date-picker'
					label="Date"
					inputFormat='dd/MM/yyyy'
					value={currentDate}
					onChange={(newDate) => {

						if (newDate != null) {
							let properTime = endOfDay(newDate);
							setCurrentDate(properTime);
							const matchedFile = historicDates?.blobList?.filter(tile => newDate.toDateString() === tile.tileDate.toDateString());
							//download file
							if (matchedFile && matchedFile[0] && matchedFile[0]!.blobName) {
								setFilename(matchedFile![0]!.blobName!.substring(0, 10));
							}
							navigate({ pathname: '/map/' + matchedFile![0]!.blobName!.substring(0, 10) }, { replace: true, });
						};

					}}
					renderInput={(params) => <TextField {...params} />}
					shouldDisableDate={(dateParam) => {

						//if dateParam is in (dateList from api) then do not disable
						const matchedTiles = historicDates?.blobList?.filter(tile => dateParam.toDateString() === tile.tileDate.toDateString());
						if (matchedTiles?.length !== 0) {
							return false;
						}
						return true;
					}}
					disabled={downloadingTiles}
				/>
				
				<div className='point-info' style={{marginBottom: '5px'}}>{hoveredFarm}</div>
				<div className='point-info'>{hoveredPoint}</div>
			</div>
		)
	}, [currentDate, downloadingTiles, historicDates, hoveredPoint, hoveredFarm, navigate]);


	function valueLabelFormat(value: number, index: number) {
		if (currentDate) {
			let date = subMinutes(currentDate, timeSliderRange - value);
			return format(date, "dd-MM HH:mm");
		}
		return value.toString();
	}

	return (

		<section className={'t2-map-container ' + getZoomLevelClass()}>
			<div className='loading-bar-spacer'>
				{downloadingTiles && <LinearProgress style={{ marginTop: '0px', zIndex: '100' }} />}
			</div>
			<MapBoxMap
				id={props.mapId}
				{...rest}

				maxZoom={16.9}
				initialViewState={{
					latitude: latitude,
					longitude: longitude,
					zoom: zoomLevel,
					pitch: 0
				}}

				interactiveLayerIds={mapLayerIds}
				cursor={cursor}

				onLoad={(e) => {
					// Intercept map load if needed
					console.log('Map onLoad');

					// Map load, remove old divs
					// Mapbox reuse maps hack, mapbox gl pushes 'map' object onto saved stack, including sub components, then rehidrates it. Then react adds back duplicate divs.
					// https://github.com/visgl/react-map-gl/blob/8db441cb36c04e806c2e00b3c3840621fa01c5f5/src/mapbox/mapbox.ts#L584
					cleanUpIds.forEach(elementId => {
						var element = document.getElementById(elementId);
						element && element.remove();
					});

					mapRef.current = e.target;
					resizeObserver.observe(e.target.getCanvasContainer());


					if (props.onLoad) {
						props.onLoad(e)
					}
				}}

				onClick={(e) => {
					console.log('Map click: ' + e.lngLat);
					mapRef.current && mouseClick(e);
				}}
				onMouseMove={mapRef.current && mouseMove} // Stops mouse over exceptions when map hasnt finished loading
				onZoomEnd={(e) => onZoomEnd(e)}
				onMoveEnd={(e) => onMoveEnd(e)}

				reuseMaps
				mapStyle={mapURL}
				mapboxAccessToken='pk.eyJ1IjoiY3N3ZWxoYW0iLCJhIjoiY2t3ZWMwb3dyMDJ3bDJ2bXVzdmwxYmxpYiJ9.aGDEPi30sptqt5Wq4YgkWQ'
			>
				{props.loading && <LinearProgress />}

				{/* Try portals for children? https://reactjs.org/docs/portals.html */}
				{rest.children}

				{showRegionLayer && nzRegions && <Source type="geojson" data={nzRegions}>
					<Layer {...regionPolyLayer} />
					<Layer {...regionLineLayer} />
				</Source>}


				{showTopographyLayer && <Source type="raster" tiles={[`https://tiles-cdn.koordinates.com/services;key=2f3f5ccb336640d18763e03d7089fc6d/tiles/v4/layer=50768,style=auto,color=${theme.palette.mode === 'dark' ? 'abfffe' : '69a8fa'}/{z}/{x}/{y}.png`]} attribution='Sourced from the LINZ Data Service and licensed for reuse under the CC BY 4.0 licence.'>
					<Layer type='raster' minzoom={12}
						paint={{
							'raster-opacity': theme.palette.mode === 'dark' ? 0.4 : 0.8,
						}} />
				</Source>}

				
				{/* This tile db file is on my PC (Haze) */}
				{!downloadingTiles && <Source type="vector" tiles={[tileServer + "/api/Tile/v1/" + filename + "/{z}/{x}/{y}.pbf"]}>
					<Layer {
						...{
							id: 'vector-point',
							//filter: ['in', 'id', "780","781"], // WORKS
							//filter: ['in', 'id', ...["780", "781"]], // works
							//filter: ["in", ['get', 'id'], ["literal", ["780", "781"]]], // works
							//filter: ["in", 'id', ["literal", ["780", "781"]]], // NO work
							filter: filter,
							type: 'circle',
							'source-layer': 'coverage',
							//minzoom: 8,
							maxzoom: 17,
							paint: {
								'circle-radius': [
									'interpolate',
									['exponential', 2],
									['zoom'],
									0, 2,
									20, 600,
								],
								// https://www.color-hex.com/color-palette/93552
								'circle-color': [
									'interpolate',
									['linear'],
									['get', 'sr'],
									0,
									'#000000',
									1,
									'#ff0000',
									2,
									'#ffc100',
									3,
									'#d6ff00',
									4,
									'#63ff00'
								],
								// Color circles by ethnicity, using a `match` expression.
								'circle-opacity': 0.4,
							}
						}}
					/>

				</Source>}
				{showFarmVatsLayer && farmVats && <Source type="geojson" data={farmVats}>
					<Layer minzoom={12}
						{...{
							id: 'farm-points',
							type: 'symbol',
							paint:{
								"icon-halo-color": "#f347bf"
							},
							layout: {
								"icon-image": "farm-15",								
							}							
						}}
					/>
				</Source>}
			</MapBoxMap>
			<section className='t2-map-overlays'>
				{rest.overlays}
				{showRegionLayer && nzRegions && <div className='region-text'>{hoveredRegion}</div>}

				<Box className='point-info-container'>
					{datesLoading ? <CentredLoading /> : showDatepicker()}
				</Box>
				<TankerGroupControl onGroupsSelected={setSelectedTankers} onSignalStrengthChosen={setSignalStrength} onLatencyChosen={setLatency} onFilterByDataChosen={setShowData}></TankerGroupControl>

				<LayerControl layerOptions={layers} styleOptions={styles} onClickLayer={onClickLayer} onClickStyle={handleStyleChange} chosenStyle={mapStyle}/>

				{showSliderLayer && <Box className='time-slider-container'>
					<Slider
						value={timeSliderValue}
						onChangeCommitted={(event: Event | SyntheticEvent, value: number | number[]) => {
							setTimeSliderCommittedValue(value as number[]);
						}}
						onChange={(event: Event, value: number | number[]) => {
							setTimeSliderValue(value as number[]);
						}}
						disableSwap={true}
						valueLabelDisplay='on'
						getAriaValueText={valueLabelFormat}
						valueLabelFormat={valueLabelFormat}
						min={0}
						max={timeSliderRange}
						step={15}
					/>
				</Box>}

			</section>
		</section>)
}
