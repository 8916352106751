import LoadingButton from "@mui/lab/LoadingButton";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller, Resolver } from "react-hook-form";
import { ApiClient, CI } from "../services";
import { EnumList, field, ValidatorObj } from "../shared/utils";
import { FormMode } from "./formHelpers";
import Button from "@mui/material/Button";
import HorizontalBox from "../components/horizontalBox";
import { useQuery } from "react-query";
import { Autocomplete, Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import './form.scss';

interface AddEditUserFormProps {
	userId: string | null;
	autoFocus?: boolean;
	submitText?: string;
	hideOptional?: boolean;
	onCancel?: () => void;
	onAdd?: (data: CI.EditApiUserDto) => void;
	onEdit?: (data: CI.EditApiUserDto) => void;
}

const defaultValues: CI.EditApiUserDto = new CI.EditApiUserDto({
	id: '',
	userName: '',
	email: '',
	password: '',
	roles: []
});

const validator: Resolver<CI.EditApiUserDto, object> = (values: CI.EditApiUserDto) => {
	var errors = ValidatorObj<CI.EditApiUserDto>();
	if (!values.userName)
		errors.userName = { message: 'Username required' };

	if (!values.email)
		errors.email = { message: 'Email required' };

	return {
		errors,
		values: values,
	};
};

export function AddEditUserForm(props: AddEditUserFormProps) {
	const { data: existingUser, isLoading: userLoading } = useQuery(['GetUser', props.userId], async () => ApiClient.apiUserV1Get(props.userId!), {
		enabled: !!props.userId
	});

	const { data: roles, isLoading: rolesLoading } = useQuery(['RolesList'], async () => ApiClient.authV1ListRoles());

	const { handleSubmit, reset: resetForm, control, formState: { errors } } = useForm<CI.EditApiUserDto>({
		defaultValues: defaultValues,
		mode: 'onChange',
		resolver: validator
	});
	const [submitting, setSubmitting] = useState(false);
	const mode: FormMode = !props.userId ? 'Add' : 'Update';

	useEffect(() => {
		if (props.userId && existingUser) {
			resetForm(existingUser);
		} else {
			resetForm(defaultValues);
		}

		return () => {
			setSubmitting(false);
			resetForm(defaultValues);
		}
	}, [resetForm, props.userId, existingUser]);

	const onSubmit: SubmitHandler<CI.EditApiUserDto> = async (data: CI.EditApiUserDto) => {
		setSubmitting(true);

		try {
			if (mode === 'Update') {
				props.onEdit && await props.onEdit(data);
			} else {
				props.onAdd && await props.onAdd(data);
			}
		} catch (ex) {
			console.error(ex);
		} finally {
			setSubmitting(false);
		}
	};

	if (userLoading || rolesLoading) {
		return <Box sx={{ margin: 'auto' }}>
			<CircularProgress />
		</Box>
	}

	return (
		//Form example https://codesandbox.io/s/react-hook-form-controller-601-j2df5
		<form className='torutek-form' onSubmit={handleSubmit(onSubmit)}>
			<section>
				<Controller
					render={({ field }) => <TextField
						{...field}
						autoFocus={props.autoFocus}
						label='Email'
						inputProps={{
							value: field.value ?? ''
						}}
						required
						error={!!errors.email?.message}
						helperText={errors.email?.message}
					/>}
					name={field<CI.EditApiUserDto>().email}
					control={control}
				/>
			</section>

			<section>
				<Controller
					render={({ field }) => <TextField
						{...field}
						label='Username'
						inputProps={{
							value: field.value ?? ''
						}}
						required
						error={!!errors.userName?.message}
						helperText={errors.userName?.message}
					/>}
					name={field<CI.EditApiUserDto>().userName}
					control={control}
				/>
			</section>
			<section>
				<Controller
					render={({ field }) => <TextField
						{...field}
						label={mode === 'Update' ? 'Change password (optional)' : 'Password'} 
						inputProps={{
							value: field.value ?? ''
						}}
						required={mode === 'Add'}
						type={'password'}
						error={!!errors.password?.message}
						helperText={errors.password?.message}
					/>}
					name={field<CI.EditApiUserDto>().password}
					control={control}
				/>
			</section>

			<section>
				<Controller
					control={control}
					name={field<CI.EditApiUserDto>().roles}
					rules={{ required: true }}
					render={({ field: { onChange, value } }) => (
						<Autocomplete
							multiple={true}
							onChange={(_, items: CI.RoleDto[] | null) => {
								items && onChange(items.map(x => x.id));
							}}
							// value={(transportOptions && value) ? transportOptions.filter(x => value.indexOf(x.id) > -1) : []}
							value={(roles && value) ? roles.filter(x => value.indexOf(x.id) > -1) : []}
							options={roles ?? []}
							getOptionLabel={(item: CI.RoleDto) => (item.name ?? '')}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Roles"
									error={!!errors.roles?.message}
									helperText={errors.roles?.message}
								/>
							)}
						/>
					)}
				/>
			</section>

			<HorizontalBox>
				<LoadingButton variant="contained" type="submit" loading={submitting}>{props.submitText ?? mode}</LoadingButton>
				{props.onCancel && <Button variant="outlined" type="button" onClick={props.onCancel} >Cancel</Button>}
			</HorizontalBox>
		</form>
	);
}
