import { OptionsObject, useSnackbar, WithSnackbarProps } from "notistack"
import React from "react"

// https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-832261019

// Must be imported at least once in the app to initialize the ref
export let SnackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator: React.FunctionComponent = () => {
	SnackbarRef = useSnackbar();
	return null;
};

// Export no-named default so consumer can name as desired/required
const toast = {
  success(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: "success" })
  },
  warning(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: "warning" })
  },
  info(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: "info" })
  },
  error(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: "error" })
  },
  toast(msg: string, options: OptionsObject = {}): void {
    SnackbarRef.enqueueSnackbar(msg, options)
  },
}

export default toast;