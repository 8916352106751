import * as React from 'react';
import { T2Map } from '../map/t2Map';

export function MapView() { 
	return <T2Map		
		mapId='mapView'
		interactiveLayerIds={['vector-point']}
		initialViewState={{
			longitude: 175.3000596, // Hamilton
			latitude: -37.7659404,
			zoom: 13
		}}
		style={{ width: '100%', height: '100%' }}
	>
		
	</T2Map>;
}